import React from "react";

class PhoneInput extends React.Component {

  setInputMask(e) {
    // e.target.value = e.target.value.replace(/[^\d0-9]/g, '').replace(/(.{1})(.{1})/g, '$1 $2').trim();
    e.target.value = e.target.value.replace(/[^\d0-9]/g, '');
  }
  
  render() {
    let inputRequired;
    let labelHint;
    
    if (this.props.isRequired === true) {
      inputRequired = 'required';
      labelHint = '';
    } else {
      inputRequired = '';
      labelHint = <span className="text-light"> (facultatif)</span>;
    }
    
    return (
      <div className="country-input-wrapper full-width">

        <div className="flex-row">
          <div>
            <label className="input-label" htmlFor="telephone">
              <p>Indicatif</p>
              <input className={`text-input tel mgr-xs ${this.props.color}`} type="text" name="country-number" id={`${this.props.id}-country-number`} defaultValue="+33" maxLength="5" required={inputRequired}/>
            </label>
          </div>
          <div className="flex-1">
            <label className="input-label" htmlFor="telephone">
              <p>Téléphone
              {labelHint}</p>
              <input onChange={this.setInputMask} 
                  className={`text-input ${this.props.color}`} 
                  type="text" 
                  name="telephone" 
                  id={`${this.props.id}-number-input`} 
                  required={inputRequired}/>
            </label>
          </div>
        </div>
      </div>
    )
  }
}

export default PhoneInput;