import React from "react";
import { graphql, StaticQuery } from 'gatsby'
import moment from 'moment'
import ReCAPTCHA from "react-google-recaptcha";

import Layout from '../../components/Layout'
import PhoneInput from "../../components/PhoneInput";
import CustomLink from "../../components/CustomLink";


class FormationForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = { 
      selectedFormationId: props.location.state && props.location.state.selectedFormationId,
      selectedFormationTitle: props.location.state && props.location.state.selectedFormationTitle,
      invalidDisplayedClass: "",
      hasLocationState: props.location.state !== undefined,
    }
  }

  setFormState = (e) => {
    const index = e.target.selectedIndex;
    const optionElement = e.target.childNodes[index]
    const id = optionElement.getAttribute('id');
    const title = e.target.value;
    this.setState({selectedFormationId: id, selectedFormationTitle: title})
  }

  showIfValid = (e) => {
    this.setState({invalidDisplayedClass: "invalid-displayed"})
  }

  render() {
    const { data } = this.props
    const {
      data: { footerData, navbarData },
    } = this.props;
    const { edges: formations } = data.formationList
    const color = "light";

    let pageTitle;
    let formationSelectClass;
    if (this.state.hasLocationState) {
      pageTitle = <h3 className="title-4 medium-weight center-text flex-column alitems-center mgb-m m-mgb-s">
                    Demande d'inscription à la formation<br/>
                    <span className="bold-weight blue-underline">{this.state.selectedFormationTitle}</span>
                  </h3>;
      formationSelectClass = "dspl-none";
    } else {
      pageTitle = <h3 className="title-4 bold-weight center-text flex-column alitems-center mgb-m m-mgb-s">
                    Demande d'inscription à une formation
                  </h3>;
      formationSelectClass = "input-label";
    }

    const selectedFormation = formations.reduce((formation, current) => {
      return formation.node.id === this.state.selectedFormationId ? formation : current;
    });

    const sortedDates = selectedFormation.node.frontmatter.dates.sort((a,b) => moment(a).diff(b))
    
    const sortedValidDates = []

    for (let i = 0; i < sortedDates.length; i++) {
      const sortedDate = sortedDates[i];
      if (moment().isBefore(sortedDate)) {
        sortedValidDates.push(sortedDate)
      }
    }

    let displayDateSelect;
    if ((this.props.location.state) && (this.props.location.state.hasValidDates === false)) {
      displayDateSelect = "dspl-none";
    } else if (this.state.selectedFormationId === undefined) {
      displayDateSelect = "dspl-none";
    } else if (sortedValidDates.length === 0) {
      displayDateSelect = "dspl-none";
    } else {
      displayDateSelect = "input-label";
    }


    return (
      <Layout footerData={footerData} navbarData={navbarData}>
        <div className="content-wrapper">
          <div className="full-width flex-row justc-center alitems-stretch mgt-l s-mgt-m
                          s-flex-column s-alitems-center">
            <div className="form-column flex-column alitems-center half-width s-full-width">
              {pageTitle}

              <form className={`flex-column justc-start alitems-stretch ${this.state.invalidDisplayedClass}`}
                  name="formation-form"
                  method="post" 
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                  action="/contact-confirmation/">
                
                <input type="hidden" name="form-name" defaultValue="formation-form" />
                <input type="hidden" name="bot-field" />


                <label className={formationSelectClass} htmlFor="formation-select">
                  <p>Formation</p>
                  {/*eslint-disable */}
                  <select className="text-input" required="required" name="formation-select" id="formation-select"
                          onChange={this.setFormState} value={this.state.selectedFormationTitle}>
                  {/*eslint-enable */}
                    <option value="">-- Sélectionnez --</option>
                    {formations.map(({ node: formation }, index) => (
                      <option key={index} 
                              value={formation.frontmatter.title} 
                              id={formation.id} >
                        {formation.frontmatter.title}
                      </option>
                    ))}
                    
                  </select>
                </label>

                <div>
                  <label className={displayDateSelect} htmlFor="formation-date-select">
                    <p>Date</p>
                    <select className={`text-input ${color}`}
                            name="formation-date-select" id="formation-date-select">

                      <option value="">-- Sélectionnez --</option>
                      {sortedValidDates.map((date, index) => {
                        const formatedDate = moment(date).format("DD/MM/YYYY")
                        return <option key={index} value={formatedDate}>{formatedDate}</option>
                      })}
                    </select>
                  </label>
                </div>

                <label className="input-label" htmlFor="nom" id="formation-name-label">
                  <p>Prénom et Nom</p>
                  <input className={`text-input ${color}`}
                        required="required" type="text" name="nom" id="formation-name-input"/>  
                </label>
                
              
                <label className="input-label" htmlFor="email" id="formation-customer-email-label">
                  <p>Adresse e-mail</p>
                  <input className={`text-input ${color}`} required="required" type="email" 
                        name="email" id="formation-customer-email--input"/>
                </label>
                
              
                <label className="input-label" htmlFor="etablissement" id="fromation-company-label">
                  <p>
                    Établissement
                  </p>
                  <input className={`text-input ${color}`} type="text" required="required"
                        name="etablissement" id="formation-company-input"/>
                </label>

                <label className="input-label" htmlFor="numero-abonne" id="numero-abonne-label">
                  <p>
                    Numéro d'abonné Electre
                    <span className="text-light"> (facultatif)</span>
                  </p>
                  <input className={`text-input ${color}`} type="text" 
                        name="numero-abonne" id="numero-abonne-input"/>
                </label>

                <PhoneInput color={color} id="formation"/>

                <fieldset className="dspl-none">
                  <label>
                    <input type="text" value={selectedFormation.node.frontmatter.email.email1} readOnly={true}
                            id="formation-electre-email-1" name="formation-electre-email-1"/>
                  </label>
                  <label>
                    <input type="text" value={selectedFormation.node.frontmatter.email.email2} readOnly={true}
                            id="formation-electre-email-1" name="formation-electre-email-2"/>
                  </label>
                  <label>
                    <input type="text" value={selectedFormation.node.frontmatter.email.email3} readOnly={true}
                            id="formation-electre-email-1" name="formation-electre-email-3"/>
                  </label>
                </fieldset>

                <label className="input-label" htmlFor="formation-message" id="formation-message">
                  <p>
                    Message
                    <span className="text-light"> (facultatif)</span>
                  </p>
                  
                  <textarea className="text-input textarea light" type="text" 
                            name="formation-message" id="formation-message">
                  </textarea>  
                </label>

                <div className="flex-row justc-center alitems-center mgt-m l-mgt-s">
                  <CustomLink 
                    linkType="external-blank"
                    linkURL="/donnees-personnelles"
                    className="grey-text">
                    Protection des données personnelles
                  </CustomLink>
                </div>

                <div className="flex-row justc-space-b alitems-start mgt-m mgb-l
                                l-mgt-s s-flex-column s-alitems-center">
                  <ReCAPTCHA sitekey={process.env.GATSBY_RECAPTCHA_KEY}/>
                  <input className="cta fill yellow mgl-s s-mgl-0 s-mgt-s s-mgb-m" onClick={this.showIfValid} type="submit" name="envoyer" value="Envoyer" />
                </div>

              </form>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default function inscriptionData({ location }) {
  return (
    <StaticQuery
      query={graphql`
      query FormationFormQuery {
        formationList : allMarkdownRemark(filter: { frontmatter: { templateKey: { eq: "formation-page" } }}) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                dates
                availableSlots
                duration
                location
                email {
                  email1
                  email2
                  email3
                }
                extract
              }
            }
          }
        }
        ...LayoutFragment
      }
      `}
      render={(data, count) => (
        <FormationForm data={data} count={count} location={location}/>
      )}
    />
  )
}